<template>
  <div>
    <v-form @submit.prevent="" ref="form" v-model="valid" class="form_container" lazy-validation>
      <v-card class="elevation-0 rounded card-container" elevation="0" max-width="60rem" width="90vw">
        <div class="d-flex align-center head-container mb-5">
          <div class="auth-logo d-flex">
            <logo type="without-text" />
          </div>
          <h3 class="mb-0 ml-2 black-orangcode--text">Sign in with {{ appName }}</h3>
        </div>
        <v-row v-if="App && App.isPublished" class="py-5">
          <v-col cols="12" md="6">
            <div>
              <div class="mb-3 app-logo-container">
                <img v-if="App.logo" :src="App.logo" alt="">
              </div>
              <div class="title_container">
                <p class="title black-orangcode--text">Login</p>
                <span class="subtitle black-orangcode--text">Access the application <a href="#">{{ App.name }}</a></span>
              </div>
            </div>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field label="Email" color="secondary" v-model="authModel.email" prepend-inner-icon="mdi-gmail"
              placeholder="youremail@domain.com" outlined :rules="[
                (v) => !!v || 'Email is required.',
                (v) => /.+@.+\..+/.test(v) || 'Please enter a valid email.',
              ]" class="w-100" />
            <v-text-field label="Password" v-model="authModel.password" type="password" color="secondary"
              prepend-inner-icon="mdi-lock-outline" placeholder="Enter your password"
              :rules="[(v) => !!v || 'Password is required.']" outlined class="w-100" />

            <div class="mb-4">
              <p class="mb-0">
                Before using {{ appName }}, please review our <a href="">Privacy Policy</a> and <a href="">Terms of Use</a>.
              </p>
            </div>
            <div class="d-flex justify-end login-bottom-container align-center">
              <a class="primary--text mr-5" href="#" @click.prevent="goTo('/identifier/signup')">Create an Account</a>

              <button :disabled="!valid || loading" @click.prevent="signIn" title="Sign In"
                class="sign-in_btn px-4 py-2 rounded-pill" :class="!valid ? 'disable-btn' : 'primary lighten-1'"
                type="submit">
                <span v-show="!loading">Sign In</span>
                <v-btn small v-show="loading" class="elevation-0 w-100 disable-btn primary lighten-1" icons :loading="true"></v-btn>
              </button>
            </div>
          </v-col>
        </v-row>
        <v-row v-else-if="App && !App.isPublished" class="py-5">
          <v-col cols="12" md="6">
            <div>
              <div class="title_container">
                <p class="title black-orangcode--text">403 Forbidden</p>
                <span class="subtitle black-orangcode--text">The application "{{ App.name }}" is not published or validated by OrangCode.</span>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row v-else class="py-5">
          <v-col cols="12" md="6">
            <div>
              <div class="title_container">
                <p class="title black-orangcode--text">401 Unauthorized</p>
                <span class="subtitle black-orangcode--text">The application requesting authentication could not be identified.</span>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-form>
  </div>
</template>


<script>
import Logo from "@/components/identifier/AuthLogo.vue";
import { login } from "@/api/user";
import { methods } from "@/utils/constant.js";
import Cookies from 'js-cookie';
import { mapGetters, mapActions } from "vuex";
import { redirectWithFilteredQuery } from "@/utils/navigation";

export default {
  components: { Logo },
  data() {
    return {
      valid: false,
      loading: false,
      authModel: {},
      appName: process.env.APP_NAME || 'OrangCode',
      languages: ["English (English)", 'French (Francais)'],
      selectLanguage: "English (English)"
    };
  },
  computed: {
    ...mapGetters(['App']),
  },
 methods: {
    ...mapActions(['fetchApp']),
    goTo(path) {
      const allowedParams = ["client_id", "redirect_uri", "next_link"];
      redirectWithFilteredQuery(path, this.$router, this.$route, allowedParams);
    },
    async initializeApp() {
      try {
        await this.fetchApp({
          clientId: this.$route.query.client_id,
        });
      } catch (error) {
        console.error("Initialization error:", error);
        methods.dispatchError(
          this.$store,
          error?.response?.data?.message || "An error occurred while initializing the application."
        );
      }
    },

    async signIn() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        try {
          await login({
            email: this.authModel.email,
            password: this.authModel.password,
          });
          this.$router.push(`/identifier/double-authentification-verification?client_id=${this.$route.query.client_id}&redirect_uri=${this.$route.query.redirect_uri}&next_link=${this.$route.query.next_link}`);
        } catch (error) {
          console.error("Login error:", error);
          methods.dispatchError(
            this.$store,
            error && error.response && error.response.data && error.response.data.message  ?error.response.data.message  : "Invalid email or password. Please try again."
          );
          await methods.sleep(2000);
        }
        this.loading = false;
      }
    },

    checkAffiliationCode() {
      const urlParams = new URLSearchParams(window.location.search);
      const affiliationCode = urlParams.get('affiliation_code');
      if (affiliationCode) {
        this.setAffiliationCodeCookie(affiliationCode);
      }
    },
    setAffiliationCodeCookie(affiliationCode) {
      Cookies.set('affiliation_code', affiliationCode, { expires: 3 });
    },
    getAffiliationCodeFromCookie() {
      return Cookies.get('affiliation_code');
    },
  },
  mounted() {
    this.initializeApp()
  }
};
</script>

<style lang="scss" scoped>



.logo {
  width: 9em;
  height: 2rem;
  background: linear-gradient(to top, #00154c, #12376e, #23487f);
  color: #fff;
  border-radius: 50px;
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  box-shadow: 20px 20px 40px #ededed, -20px -20px 40px #ffffff;
  overflow: hidden;
}

.logo span {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: top 0.5s;
}


.form_container {
  //width: fit-content;
  // height: fit-content;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: center;
  gap: 15px;
  // padding: .5rem 1rem;
  padding: .2rem;
  overflow-x: hidden;
  overflow-y: hidden;
  // padding: 50px 40px 20px 40px;
  // background-color: #ffffff;
  // box-shadow: 0px 106px 42px rgba(0, 0, 0, 0.01),
  //   0px 59px 36px rgba(0, 0, 0, 0.05), 0px 26px 26px rgba(0, 0, 0, 0.09),
  //   0px 7px 15px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
  font-family: "Inter", sans-serif;
}



.icon {
  width: 20px;
  position: absolute;
  z-index: 99;
  left: 12px;
  bottom: 9px;
}

.input_label {
  font-size: 0.75rem;
  color: #8b8e98;
  font-weight: 600;
}

.input_field {
  width: auto;
  height: 40px;
  padding: 0 0 0 40px;
  border-radius: 7px;
  outline: none;
  border: 1px solid #e5e5e5;
  filter: drop-shadow(0px 1px 0px #efefef) drop-shadow(0px 1px 0.5px rgba(239, 239, 239, 0.5));
  transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
}

.input_field:focus {
  border: 1px solid transparent;
  box-shadow: 0px 0px 0px 2px #242424;
  background-color: transparent;
}


.sign-in_ggl {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: #ffffff;
  border-radius: 7px;
  outline: none;
  color: #242424;
  border: 1px solid #e5e5e5;
  filter: drop-shadow(0px 1px 0px #efefef) drop-shadow(0px 1px 0.5px rgba(239, 239, 239, 0.5));
  cursor: pointer;
}

.sign-in_apl {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: #212121;
  border-radius: 7px;
  outline: none;
  color: #ffffff;
  border: 1px solid #e5e5e5;
  filter: drop-shadow(0px 1px 0px #efefef) drop-shadow(0px 1px 0.5px rgba(239, 239, 239, 0.5));
  cursor: pointer;
}

.separator {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  color: #8b8e98;
}

.separator .line {
  display: block;
  width: 100%;
  height: 1px;
  border: 0;
  background-color: #e8e8e8;
}

.note {
  font-size: 0.75rem;
  // color: #8b8e98;
  // text-decoration: underline;
  transition: all 300ms;
  padding: .5rem .5rem;
  border-radius: 5px;
}

.note:hover {
  // font-size: 0.85rem;
  background-color: rgb(218, 218, 218);
  // color: #8b8e98;
  // text-decoration: underline;
}
</style>