<template>
  <v-app id="App" class="">
    <div class="auth-container dash">
      <div class="h-100 d-flex justify-center align-center bg-dash">
        <div>
          <router-view></router-view>
          <div class="">
            <div class="px-2 d-md-flex justify-space-between text-center  pt-5">
              <div class="">
                <div class="language-container">
                  <!-- <v-select  v-model="selectLanguage" :items="languages"  filled   dense></v-select> -->
                </div>
              </div>
              <!-- Footer Links -->
              <div class="links">
                <a v-if="showForgetLink" class="note mr-3 primary--text"
                  @click.prevent="goTo('/identifier/forget-password')">
                  Forget password?
                </a>
                <a v-if="showCreateAccount" class="note mr-3 primary--text" @click.prevent="goTo('/identifier/signup')">
                  Create account
                </a>
                <a v-if="showSignIn" class="note primary--text" @click.prevent="goTo('/identifier/signin')">
                  Sign in
                </a>
              </div>


            </div>
          </div>
        </div>
      </div>
    </div>

  </v-app>
</template>

<script>
import { redirectWithFilteredQuery } from "@/utils/navigation";

export default {
  data() {
    return {
      drawer: false,
      profileDialog: false,
      menu: false,
      profileModal: {},
    };
  },
  computed: {
    // Computed properties to manage the visibility of links
    showForgetLink() {
      // Add logic for showing/hiding the Forget Password link
      return ["Signin", "VerifyRestaurationCode"].includes(this.$route.name);
    },
    showCreateAccount() {
      // Add logic for showing/hiding the Create Account link
      return this.$route.name === "Signin";
    },
    showSignIn() {
      // Add logic for showing/hiding the Sign In link
      return ["ForgetPassword", "SignUp"].includes(this.$route.name);
    },
  },
  methods: {
    goTo(path, additionalQuery = {}) {
      const allowedParams = ["client_id", "redirect_uri", "next_link"]
      redirectWithFilteredQuery(path, this.$router, this.$route, allowedParams, additionalQuery)
    },
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
    manageProfileDialog() {
      this.profileDialog = !this.profileDialog;
    },
  },
};
</script>
<style>
#App {
  font-size: 0.9rem;
}

.auth-container {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}

a {
  font-weight: 600;
  text-decoration: none;
}
</style>