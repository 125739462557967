<template>
    <div class="redirect-page">
      <div class="content">
        <h1>Redirecting to {{ appName }}</h1>
        <p class="countdown">Redirecting in {{ countdown }}...</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        countdown: 3, // Starting countdown value
        appName:`${process.env.APP_NAME} App` || "OrangCode App"
      };
    },
    mounted() {
      this.startCountdown();
    },
    methods: {
      startCountdown() {
        const interval = setInterval(() => {
          if (this.countdown > 0) {
            this.countdown -= 1;
          } else {
            clearInterval(interval);
            this.redirectToApp();
          }
        }, 1000); // Countdown interval in milliseconds
      },
      redirectToApp() {
        window.location.href = process.env.ORANGCODE_APP_LINK || "https://app.orangcode.com";
      },
    },
  };
  </script>
  
  <style scoped>
  .redirect-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-family: 'Arial', sans-serif;
    color: black;
    text-align: center;
  }
  
  .content {
    background: rgba(255, 255, 255, 0.6);
    padding: 30px 50px;
    border-radius: 10px;
  }
  
  h1 {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
  
  .countdown {
    font-size: .9rem;
    /* font-weight: bold; */
  }
  </style>
  