<template>
  <div class="">
    <v-form ref="form" v-model="valid" class="form_container" lazy-validation>
      <v-card class="elevation-0 rounded px-md-5 card-container " elevation="0" max-width="60rem" width="90vw">
        <div class=" px-3 mt-5 app-logo-container d-flex justify-start ">
          <logo type="without-text" align="start" />
        </div>

        <v-stepper v-model="stepperModel" class="elevation-0">

          <v-stepper-items>
            <v-stepper-content class="px-0 pt-2" v-for="step of steps" :key="step.key" :step="step.key">
              <v-row class="pb-5 px-3">
                <v-col cols="12" md="6">
                  <div>

                    <div class="title_container">
                      <p class="title black-orangcode--text">{{ step.title }}</p>
                      <span class="subtitle black-orangcode--text">{{ step.subtitle }}</span>
                    </div>
                  </div>
                </v-col>

                <v-col cols="12" md="6" class="mt-md-5 ">
                  <v-form :ref="`step-${step.key}`" lazy-validation>

                    <div v-if="stepperModel == 1">
                      <v-text-field label="LastName" color="secondary" prepend-inner-icon="mdi-account"
                        placeholder="Your Last Name" outlined v-model="actorModal.lastName"
                        :rules="[(v) => !!v || 'Your last name is required']" class="w-100" />
                      <v-text-field label="FirstName" color="secondary" prepend-inner-icon="mdi-account"
                        placeholder="Your First Name" outlined v-model="actorModal.firstName"
                        :rules="[(v) => !!v || 'Your first name is required']" class="w-100" />
                      <v-text-field label="Email" color="secondary" v-model="actorModal.email"
                        prepend-inner-icon="mdi-gmail" placeholder="youremail@host.com" outlined :rules="[
                          (v) => !!v || 'E-mail is required',
                          (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                        ]" class="w-100" />

                    </div>

                    <div v-if="stepperModel == 2">
                      <v-text-field label="Company (Optional)" color="secondary" prepend-inner-icon="mdi-pillar"
                        placeholder="Company" outlined v-model="actorModal.company" class="w-100" />
                      <div class="d-flex align-center justify-start country-container my-0 py-2 px-2 mb-3">
                        <span class="mr-2 gray--text"> <v-icon>mdi-flag-outline</v-icon> Country of residence : </span>
                        <vue-country-code class="country-container-input my-0 py-0" ref="vcc"
                          @onSelect="onCountrySelect"></vue-country-code>
                      </div>
                    </div>
                    <div v-if="stepperModel == 3">
                      <v-text-field label="Password" color="secondary" v-model="actorModal.password"
                        prepend-inner-icon="mdi-lock-outline" placeholder="yourpassword"
                        :rules="[(v) => !!v || 'Password is required']" outlined class="w-100" type="Password" />
                      <v-text-field label="Confirm password" color="secondary" prepend-inner-icon="mdi-lock-outline"
                        v-model="actorModal.confirmPassword" placeholder="yourpassword"
                        :rules="[(v) => !!v || 'Confirmed password is required']" outlined type="Password"
                        class="w-100" />
                    </div>
                    <div v-if="stepperModel == 4">
                      <p class="mb-0">By creating an account, you agree to our <a href=""
                          class="font-weight-bold">Terms</a>
                        and
                        have
                        read and acknowledge the <a href="" class="font-weight-bold">Global Privacy Statement</a></p>
                    </div>
                  </v-form>

                  <div class="d-flex justify-end login-bottom-container align-center pt-5">
                    <button v-if="!isFirstStep()" :disabled="!valid || loading == true" @click.prevent="goBack"
                      title="Go Back" class="sign-in_btn px-4 py-2 rounded-pill primary--text "
                      :class="!valid || loading ? 'disable-btn' : 'white lighten-1'" type="submit">
                      <span v-show="!loading">Go Back</span>
                      
                    </button>
                    <button v-if="!isLastStep()" :disabled="!valid || loading == true" @click.prevent="nextStep"
                      title="Next Step" class="sign-in_btn px-4 py-2 rounded-pill "
                      :class="!valid ? 'disable-btn' : 'primary lighten-1'" type="submit">
                      <span v-show="!loading">Next</span>
                      <v-btn small v-show="loading" class="elevation-0 w-100 disable-btn primary lighten-1" icons
                        :loading="true"></v-btn>
                    </button>
                    <button v-if="isLastStep()" :disabled="!valid || loading" @click.prevent="signUp" title="Sign In"
                      class="sign-in_btn  px-4 py-2 " :class="!valid ? 'disable-btn' : 'primary lighten-1'">
                      <span v-show="!loading">Sign Up</span>
                      <v-btn small v-show="loading" class="elevation-0 w-100 disable-btn primary lighten-1" icons
                        :loading="true"></v-btn>

                    </button>

                  </div>


                </v-col>
              </v-row>
            </v-stepper-content>
            <v-stepper-content step="100">
              <v-row class="py-5 px-3">
                <v-col cols="12" md="6">
                  <div>
                    <div class="mb-3 app-logo-container d-flex justify-start ">
                      <logo type="without-text" align="start" />
                    </div>
                    <div class="title_container">
                      <p class="title black-orangcode--text">Create an {{ appName }} account</p>
                      <span class="subtitle black-orangcode--text">Fill in the fields</span>
                    </div>
                  </div>
                </v-col>

                <v-col cols="12" md="6">




                  <button :disabled="!valid || loading" @click.prevent="signUp" title="Sign In" class="sign-in_btn"
                    :class="!valid ? 'disable-btn' : 'primary lighten-1'">
                    <span v-show="!loading">Sign Up</span>
                    <v-btn small v-show="loading" class="elevation-0 w-100 disable-btn primary lighten-1" icons
                      :loading="true"></v-btn>

                  </button>


                  <router-link class="note primary--text" to="/identifier/login">Already have account ? Login</router-link>
                  <p class="note mb-0">Terms of use &amp; Conditions</p>

                </v-col>
              </v-row>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>

    </v-form>
  </div>
</template>

<script>
import Logo from "@/components/identifier/AuthLogo.vue";

import { createUser } from "@/api/user";
import { methods } from "@/utils/constant.js";
import VueCountryCode from "vue-country-code";
import Cookies from 'js-cookie';
import { redirectWithFilteredQuery } from "@/utils/navigation";

export default {
  components: { Logo, VueCountryCode },
  data() {
    return {
      valid: false,
      appName: process.env.APP_NAME || 'OrangCode',
      loading: false,
      actorModal: {
        role: "super"
      },
      stepperModel: 1,
      lastStepp: 4,
      steps:  [
  {
    key: 1,
    title: `Create an ${process.env.APP_NAME || 'OrangCode'} account`,
    subtitle: "Provide your personal information."
  },
  {
    key: 2,
    title: `Add Additional Details`,
    subtitle: "Enter your company name (optional) and country of residence."
  },
  {
    key: 3,
    title: `Set Up Your Password`,
    subtitle: "Secure your account with a password and confirm it."
  },
  {
    key: 4,
    title: `Review and Accept`,
    subtitle: "Agree to the Terms and Privacy Policy to complete your account creation."
  }
]


    };
  },
  methods: {
    goTo(path,additionalQuery={}){
      const allowedParams = ["client_id","redirect_uri","next_link"]
      redirectWithFilteredQuery(path,this.$router,this.$route,allowedParams,additionalQuery)
    },
    isLastStep() {
      return this.stepperModel == this.steps[this.steps.length - 1].key
    },
    isFirstStep() {
      return this.stepperModel == this.steps[0].key
    },
    nextStep() {
      if (!this.isLastStep()) {
        // before next step check field validation 
        if (this.$refs['step-' + this.stepperModel][0].validate()) {
          this.stepperModel += 1
        }
      }
    },
    goBack() {
      if (!this.isFirstStep()) {
        this.stepperModel -= 1
      }
    },
    async signUp() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        try {
          const schema = this.actorModal
         
          await createUser({
            schema: schema
          });
          methods.dispatchSuccess(
            this.$store,
            "Account create successfully  " + this.actorModal.email
          );
          const additionalQuery = {
            email:this.actorModal.email
          }
          this.goTo(`/identifier/verify-email`,additionalQuery)

        } catch (error) {
          console.log("login error", error);
          methods.dispatchError(
            this.$store,
            error.response.data.message
          );
        }
        this.loading = false;
      }
    },
    onCountrySelect(response) {
      let name = response.name
      if (name.includes("(")) {
        name = name.split("(")
        name = name[0].trim()
        this.actorModal.country = name
      }
    },

    checkAffiliationCode() {
      const urlParams = new URLSearchParams(window.location.search);
      let affiliationCode = urlParams.get('affiliation_code');
      if (affiliationCode) {
        this.actorModal.affiliationCode = affiliationCode;
        this.setAffiliationCodeCookie(affiliationCode)
      } else {
        affiliationCode = this.getAffiliationCodeFromCookie()
        if (affiliationCode) {
          this.actorModal.affiliationCode = affiliationCode;
        }
      }
    },
    setAffiliationCodeCookie(affiliationCode) {
      // Définit le cookie avec une durée de vie de 3 jours
      Cookies.set('affiliation_code', affiliationCode, { expires: 3 });
    },
    getAffiliationCodeFromCookie() {
      return Cookies.get('affiliation_code');

    }

  },
  mounted() {
    localStorage.setItem("user-token", '')

    this.checkAffiliationCode();
  }
};
</script>

<style lang="scss" scoped>
.form_container {
  gap: 15px;
  padding: .2rem;
  overflow-x: hidden;
  overflow-y: hidden;
  font-family: "Inter", sans-serif;
}


.country-container {
  width: 100%;
  border: 1px solid rgb(191, 191, 191);
  border-radius: 5px;
}

.country-container-input {
  border: none;
}

.logo {
  width: 9em;
  height: 2rem;
  background: linear-gradient(to top, #00154c, #12376e, #23487f);
  color: #fff;
  border-radius: 50px;
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  box-shadow: 20px 20px 40px #ededed, -20px -20px 40px #ffffff;
  overflow: hidden;
}

.logo span {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: top 0.5s;
}



.input_container {
  width: 100%;
  height: fit-content;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.icon {
  width: 20px;
  position: absolute;
  z-index: 99;
  left: 12px;
  bottom: 9px;
}

.input_label {
  font-size: 0.75rem;
  color: #8b8e98;
  font-weight: 600;
}

.input_field {
  width: auto;
  height: 40px;
  padding: 0 0 0 40px;
  border-radius: 7px;
  outline: none;
  border: 1px solid #e5e5e5;
  filter: drop-shadow(0px 1px 0px #efefef) drop-shadow(0px 1px 0.5px rgba(239, 239, 239, 0.5));
  transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
}

.input_field:focus {
  border: 1px solid transparent;
  box-shadow: 0px 0px 0px 2px #242424;
  background-color: transparent;
}

.sign-in_ggl {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: #ffffff;
  border-radius: 7px;
  outline: none;
  color: #242424;
  border: 1px solid #e5e5e5;
  filter: drop-shadow(0px 1px 0px #efefef) drop-shadow(0px 1px 0.5px rgba(239, 239, 239, 0.5));
  cursor: pointer;
}

.sign-in_apl {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: #212121;
  border-radius: 7px;
  outline: none;
  color: #ffffff;
  border: 1px solid #e5e5e5;
  filter: drop-shadow(0px 1px 0px #efefef) drop-shadow(0px 1px 0.5px rgba(239, 239, 239, 0.5));
  cursor: pointer;
}

.separator {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  color: #8b8e98;
}

.separator .line {
  display: block;
  width: 100%;
  height: 1px;
  border: 0;
  background-color: #e8e8e8;
}

.note {
  font-size: 0.75rem;
  color: #8b8e98;
  text-decoration: underline;
}
</style>