export const redirectWithFilteredQuery = (to, router, route, allowedParams = [], additionalQuery = {}) => {
    const currentQueryParams = route.query;

    // Filter the current query params based on allowedParams
    const filteredParams = Object.fromEntries(
        Object.entries(currentQueryParams).filter(([key]) => allowedParams.includes(key))
    );

    // Merge filteredParams with additionalQuery
    const mergedQuery = {...filteredParams, ...additionalQuery };

    // Redirect to the new route with merged query parameters
    router.push({ path: to, query: mergedQuery });


};