import axios from "axios";
const mutations = {
    SET_APP(state, data) {
        state.app = data
    },
}
const state = () => ({
    app: null,
})
const getters = {
    App(state) {
        return state.app
    },
}

const actions = {
    /**
     * @App
     */
    fetchApp({ commit }, { clientId }) {

        return new Promise((resolve, reject) => {
            axios
                .get(`/app/${clientId}/fetch`)
                .then(res => {
                    commit("SET_APP", res.data.data);

                    console.log("ffetchApp", res.data.data)
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
}




export default {
    state,
    actions,
    mutations,
    getters,
}