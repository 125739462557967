<template>
    <div @click="goTo('/')" class="logos " :class="getContainerClass">
      <div v-if="type=='inline'" class="">
        <img :src="require('@/assets/logo.png')" alt=""/>
      </div>
      <div v-if="type=='without-text'" class="">
        <img :src="require('@/assets/logoWithoutText.png')" alt=""/>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props:{
      type:{
        type:String,
        default:"inline"
      },
      align:{
        type:String,
        default:"center"
      }
    },
    computed:{
      getContainerClass(){
        let output = "d-flex justify-center   align-center "
        if(this.align =="start"){
        output = "d-flex justify-start   align-center "

        }
        return output 
      }
    },
    methods: {
      goTo(link) {
        this.$router.push(link);
      },
    },
  };
  </script>
  
  <style>
  .logos {
    width: 15rem;
    /* height: 8rem; */
    padding: 0;
    margin: 0;
  }
  .logos img{
    width: 100%;
  
    height: 100%;
    /* height: 2rem; */
  }
  
  .logo span {
    /* font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: top 0.5s; */
  }
  </style>