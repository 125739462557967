/**
 * Axios
 */
import axios from "axios";

/**
 * @login
 */

export const login = (schema) => {
    const data = {
        password: schema.password,
    };

    data.email = schema.email;
    return new Promise((resolve, reject) => {
        axios.post("/user/login", data).then(
            (res) => {
                const token = res.data.token;
                localStorage.setItem("temp-user-token", token);
                // localStorage.setItem("user-data", data);
                localStorage.setItem("user-email", data.email);
                resolve(res.data);
            },
            (error) => {
                reject(error);
            }
        );
    });
};

export const logout = () => {
    localStorage.setItem("user-token", "");
    localStorage.setItem("temp-user-token", "");
    localStorage.setItem("user-data", "");
    localStorage.setItem("user-token", "");
    localStorage.setItem("user-email", "");
    location.reload();
};

/**
 * @generateRestaurationCode
 */

export const generateRestaurationCode = (schema) => {
    const data = {
        email: schema.email,
    };

    return new Promise((resolve, reject) => {
        axios.post("/user/generate-password-restoration-code", data).then(
            (res) => {

                resolve(res.data);
            },
            (error) => {
                reject(error);
            }
        );
    });
};
/**
 * @verifyDoubleAuthentificationCode
 */

export const verifyDoubleAuthentificationCode = (schema) => {
    const data = {
        token: localStorage.getItem("temp-user-token"),
        code: schema.code,
    };

    return new Promise((resolve, reject) => {
        axios.post("/system/activatedoubleauthtoken", data).then(
            (res) => {
                localStorage.setItem("temp-user-token", '');
                // localStorage.setItem("user-username", '');
                localStorage.setItem("user-token", res.data.token);
                localStorage.setItem("redirect-to-user-account", 'yes');

                resolve(res.data);
            },
            (error) => {
                reject(error);
            }
        );
    });
};
/**
 * @resendDoubleAuthentificationCode
 */

export const resendDoubleAuthentificationCode = () => {
    const data = {
        token: localStorage.getItem("temp-user-token"),
    };

    return new Promise((resolve, reject) => {
        axios.post("/system/resenddoubleauthcode", data).then(
            (res) => {


                resolve(res.data);
            },
            (error) => {
                reject(error);
            }
        );
    });
};
/**
 * @verifyRestaurationCode
 */

export const verifyRestaurationCode = (schema) => {
    const data = {
        email: schema.email,
        code: schema.code,
    };

    return new Promise((resolve, reject) => {
        axios.post("/user/verify-restoration-code", data).then(
            (res) => {

                resolve(res.data);
            },
            (error) => {
                reject(error);
            }
        );
    });
};

/**
 * @activateUserAccount
 */

export const activateUserAccount = (schema) => {
    const data = {
        email: schema.email,
        code: schema.code,
    };

    return new Promise((resolve, reject) => {
        axios.post("/user/activate-account", data).then(
            (res) => {

                resolve(res.data);
            },
            (error) => {
                reject(error);
            }
        );
    });
};
/**
 * @restoreNewPassword
 */

export const restoreNewPassword = (schema) => {
    const data = {
        // email: schema.email,
        token: schema.token,
        // code: schema.code,
        password: schema.password,
    };

    return new Promise((resolve, reject) => {
        axios.post("/user/restore-password", data).then(
            (res) => {

                resolve(res.data);
            },
            (error) => {
                reject(error);
            }
        );
    });
};

/**
 * @User
 * ***************************************
 */

/**
 * @post
 */
export const createUser = async(data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/user`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};
/**
 * @update
 */
export const updateUser = async(data) => {
    return new Promise((resolve, reject) => {
        axios.put(`/user/${data.id}`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};
/**
 * @update
 */
export const updateProfile = async(data) => {
    return new Promise((resolve, reject) => {
        axios.put(`/user/profile`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};
/**
 * @delete
 */
export const deleteUser = async(data) => {
    return new Promise((resolve, reject) => {
        axios.delete(`/user/${data.id}`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};
/**
 * @get
 */
export const requestToSharedAuthInfoWithApp = async(data) => {
    return new Promise((resolve, reject) => {
        axios.get(`/app/${data.clientId}/shared-auth-information-with-app?redirect_uri=${data.redirectUri}&next_link=${data.nextLink}`).then(
            res => {

                if (res.data.redirectionLink) {
                    // redirect
                    location.href = res.data.redirectionLink
                }
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};