<template>
  <div class="h-100 d-flex justify-center align-center">
    <v-form ref="form" v-model="valid" class="form_container" lazy-validation>
      <v-card class="elevation-0 rounded  card-container " elevation="0" max-width="60rem" width="90vw">
        <div class="d-flex align-center head-container mb-5">
          <div class="auth-logo d-flex">
            <logo type="without-text" />
          </div>
          <h3 class="mb-0 ml-2 black-orangcode--text">Sign in with {{ appName }}</h3>
        </div>
        <div v-if="App && App.isPublished" class=" px-3 mt-5 app-logo-container d-flex justify-start ">
          <img v-if="App.logo" :src="App.logo" alt="">
        </div>

        <v-row v-if="App && App.isPublished" class="pb-5 px-3">
          <v-col cols="12" md="6">
            <div>
              <div class="title_container">
                <p class="title">Help us protect your account</p>
                <span class="subtitle">For added security, you'll need to verify your identity. We've sent a
                  verification
                  code
                  to {{ obfuscateEmail }}
                </span>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field label="Verification Code" color="secondary" v-model="authModel.code"
              prepend-inner-icon="mdi-key" placeholder="code" outlined :rules="[
                (v) => !!v || 'Code is required',

              ]" class="w-100" />


            <button :disabled="!valid || resendLoading || loading" @click.prevent="verifyCode" title="Sign In"
              class="sign-in_btn" :class="!valid ? 'disable-btn' : 'primary lighten-1'">
              <span v-show="!loading">Verification du code</span>
              <v-btn small v-show="loading" class="elevation-0 w-100 disable-btn primary lighten-1" icons
              :loading="true"></v-btn>
            </button>

            <button :disabled="resendLoading || loading" @click.prevent="resendCode" title="Sign In "
              class="sign-in_ggl primary--text mt-2">
              <span v-show="!resendLoading">Resend code </span>
              <v-btn small v-show="resendLoading"  class="elevation-0 w-100  primary--text" icons
              :loading="true"></v-btn>
            </button>
          </v-col>



        </v-row>
      </v-card>
      <!-- <p class="note mb-0">Terms of use &amp; Conditions</p> -->
    </v-form>
  </div>
</template>

<script>
import Logo from "@/components/identifier/AuthLogo.vue";
import { verifyDoubleAuthentificationCode, resendDoubleAuthentificationCode } from "@/api/user";
import { methods } from "@/utils/constant.js";
import { mapGetters, mapActions } from "vuex";

export default {
  components: { Logo },
  data() {
    return {
      valid: false,
      loading: false,
      resendLoading: false,
      authModel: {},
      appName: process.env.APP_NAME || 'OrangCode',
    };
  },
  computed: {
    ...mapGetters(['App']),
    obfuscateEmail() {
      return methods.obfuscateEmail(localStorage.getItem("user-email"))
    }
  },
  methods: {
    ...mapActions(['fetchApp']),

    async initializeApp() {
      try {
        await this.fetchApp({
          clientId: this.$route.query.client_id
        })
      } catch (error) {
        console.log("login error", error);
        methods.dispatchError(
          this.$store,
          error && error.response && error.response.data && error.response.data.message || "Something went wrong"
        );
      }
    },
    async verifyCode() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        try {
         const response = await verifyDoubleAuthentificationCode({
            code: this.authModel.code
          });

          console.log("response",response)

          methods.dispatchSuccess(this.$store,
            "Welcome " + localStorage.getItem("user-email"));

          // save client id and redirect uri on localstorage
          localStorage.setItem("target_app_client_id", this.$route.query.client_id)
          localStorage.setItem("target_app_redirect_uri", this.$route.query.redirect_uri)
          localStorage.setItem("target_app_next_link", this.$route.query.next_link)

          // this.$router.push(`/account/user/authorize-app-to-get-auth-information?client_id=${this.$route.query.client_id}&redirect_uri=${this.$route.query.redirect_uri}`)
          location.reload()
        } catch (error) {
          console.log("login error", error.response);
          methods.dispatchError(
            this.$store,
            error && error.response && error.response.data && error.response.data.message || "Something went wrong"
          );
        }
        this.loading = false;
      }
    },
    async resendCode() {
      this.resendLoading = true;

      try {
        await resendDoubleAuthentificationCode({
          code: this.authModel.code
        });

        methods.dispatchSuccess(this.$store,
          "Verification code sent ");
      } catch (error) {
        methods.dispatchError(
          this.$store,
          error && error.response && error.response.data && error.response.data.message || "Something went wrong"
        );
      }
      this.resendLoading = false;
    }
  },
  mounted() {
    this.initializeApp()
  }
};
</script>

<style lang="scss" scoped>
.logo {
  width: 9em;
  height: 2rem;
  background: linear-gradient(to top, #00154c, #12376e, #23487f);
  color: #fff;
  border-radius: 50px;
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  box-shadow: 20px 20px 40px #ededed, -20px -20px 40px #ffffff;
  overflow: hidden;
}

.logo span {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: top 0.5s;
}

.form_container {

  gap: 15px;
  padding: .2rem;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: #ffffff;
  border-radius: 11px;
  font-family: "Inter", sans-serif;
}


.input_container {
  width: 100%;
  height: fit-content;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.icon {
  width: 20px;
  position: absolute;
  z-index: 99;
  left: 12px;
  bottom: 9px;
}

.input_label {
  font-size: 0.75rem;
  color: #8b8e98;
  font-weight: 600;
}

.input_field {
  width: auto;
  height: 40px;
  padding: 0 0 0 40px;
  border-radius: 7px;
  outline: none;
  border: 1px solid #e5e5e5;
  filter: drop-shadow(0px 1px 0px #efefef) drop-shadow(0px 1px 0.5px rgba(239, 239, 239, 0.5));
  transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
}

.input_field:focus {
  border: 1px solid transparent;
  box-shadow: 0px 0px 0px 2px #242424;
  background-color: transparent;
}

.sign-in_btn {
  width: 100%;
  height: 40px;
  border: 0;
  //   background: #115dfc;
  border-radius: 7px;
  outline: none;
  color: #ffffff;
  cursor: pointer;
}

.sign-in_ggl {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: #ffffff;
  border-radius: 7px;
  outline: none;
  color: #242424;
  border: 1px solid #e5e5e5;
  filter: drop-shadow(0px 1px 0px #efefef) drop-shadow(0px 1px 0.5px rgba(239, 239, 239, 0.5));
  cursor: pointer;
}

.sign-in_apl {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: #212121;
  border-radius: 7px;
  outline: none;
  color: #ffffff;
  border: 1px solid #e5e5e5;
  filter: drop-shadow(0px 1px 0px #efefef) drop-shadow(0px 1px 0.5px rgba(239, 239, 239, 0.5));
  cursor: pointer;
}

.separator {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  color: #8b8e98;
}

.separator .line {
  display: block;
  width: 100%;
  height: 1px;
  border: 0;
  background-color: #e8e8e8;
}

.note {
  font-size: 0.75rem;
  color: #8b8e98;
  text-decoration: underline;
}
</style>